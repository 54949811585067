import React from "react";
import PromotionHeader from "../../components/PromotionHeader/PromotionHeader";
import PromotionFooter from "../../components/PromotionFooter/PromotionFooter";
import SummerOfferBannerEN from "../../images/summer-offers-en.png";
import SummerOfferBannerAR from "../../images/summer-offers-ar.png";
import "../SummerOffer/SummerOffer.css";
import OfferForm from "../../components/OfferForm/OfferForm";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";

const SummerOffer = () => {
  const id = "Summer 2024 Offer";
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const bannerImage =
    i18n.language === "ar" ? SummerOfferBannerAR : SummerOfferBannerEN;

  return (
    <div className={i18n.language === "ar" ? "rtl" : ""}>
      <div className="align-header">
        <PromotionHeader />
        {/* <a className="brand-btn" href="tel:8003048884">Call Us</a> */}
        <div className="language-switcher">
          {i18n.language === "en" ? (
            <button className="brand-btn" onClick={() => changeLanguage("ar")}>
              العربية
            </button>
          ) : (
            <button className="brand-btn" onClick={() => changeLanguage("en")}>
              ENGLISH
            </button>
          )}
        </div>
      </div>

      <a href="tel:8003048884">
        <div className="cta-position">
          <div className="cta-btn">
            {/* <Icon icon="iconamoon:shield-yes-fill" className="whyChooseIcon" /> */}
            <Icon icon="material-symbols:call" className="call-icon"/>
          </div>
        </div>
      </a>

      <div className="offersBannerContent">
        <div className="offersBanner">
          <img src={bannerImage} alt="Summer Offer Banner" />
          {/* <div className="offersBannerCaption">
            <h1>Summer</h1>
            <h1>2024 Offer</h1>
            <p>Offer valid till 31st March</p>
          </div> */}
        </div>
      </div>
      <Container>
        <div className="offerTextContent section-title">
          <h2 className="text-center mt-3 mb-5">
            {t("Benefit of one from the following offers and more….")}{" "}
          </h2>
          <Row>
            <Col sm={12} md={6} lg={3}>
              <div className="section-title">
                <h2 className="mb-3">{t("Option 1")}</h2>
              </div>
              <ul className="offerContentList">
                <li>
                  <p>{t("Free 2 installments")}</p>
                </li>
                <li>
                  <p>{t("0% Down Payment")}</p>
                </li>
                <li>
                  <p>{t("0% Admin Fees")}</p>
                </li>
              </ul>
            </Col>

            <Col sm={12} md={6} lg={3}>
              <div className="section-title">
                <h2 className="mb-3">{t("Option 2")}</h2>
              </div>
              <ul className="offerContentList">
                <li>
                  <p>{t("Postpone 3 installments")}</p>
                </li>
                <li>
                  <p>{t("0% Down Payment")}</p>
                </li>
                <li>
                  <p>{t("0% Admin Fees")}</p>
                </li>
              </ul>
            </Col>

            <Col sm={12} md={6} lg={3}>
              <div className="section-title">
                <h2 className="mb-3">{t("Option 3")}</h2>
              </div>
              <ul className="offerContentList">
                <li>
                  <p>{t("3 Free installments")}</p>
                </li>
                <li>
                  <p>{t("0% Admin Fees")}</p>
                </li>
              </ul>
            </Col>

            <Col sm={12} md={6} lg={3}>
              <div className="section-title">
                <h2 className="mb-3">{t("Option 4")}</h2>
              </div>
              <ul className="offerContentList">
                <li>
                  <p>{t("40% Down Payment")}</p>
                </li>
                <li>
                  <p>{t("60% Balloon Payment")}t</p>
                </li>
                <li>
                  <p>{t("0% Admin Fees")}</p>
                </li>
                <li>
                  <p>{t("0% Profit Rate")}</p>
                </li>
              </ul>
            </Col>
          </Row>
        </div>

        <ul className="offerTCList">
          <li>
            <p>{t("*Offer's terms and conditions will apply.")}</p>
          </li>
          <li>
            <p>
              {t(
                "*The car in the ad might be different from the car displayed at the showrooms."
              )}
            </p>
          </li>
          <li>
            <p>
              {t(
                "*Some of these offers are applicable on specific brands and models."
              )}
            </p>
          </li>
          <li>
            <p>
              {t(
                "*Each offer is not valid with other offers and should not be added to another offer."
              )}
            </p>
          </li>
          <li>
            <p>
              {t(
                "*Offers are subjected to the terms and conditions of the financing entities."
              )}
            </p>
          </li>
        </ul>
      </Container>

      <OfferForm id={id} />
      <PromotionFooter />
    </div>
  );
};

export default SummerOffer;
